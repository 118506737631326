const buildSchema = edges => {
  let itemList = []
  let newURL = ``
  var schema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": itemList,
  }
  edges.map((e, index) => {
    
    e.node.data.product_title != null ?  newURL = `/products/cd-${e.node.data.product_title.trim().toLowerCase().replace(/\s+/g, '-')}/`
    : newURL = `blank`
    
    let price = 0


    if( e.node.data.product_price != null){
      price = e.node.data.product_price.replace(/AUD|AU|\$/g, '');      
    }

    

  
    
    var item = {
      "@type": "ListItem",
      "position": index,      
      "item": {
        "@type": "Product",
        "url": newURL,
        "name": e.node.data.product_title,
        "image": [e.node.data.product_image],
        "description": e.node.data.product_description,        
        "offers": [
          {
            "@type": "Offer",
            "url": newURL,
            "priceCurrency": "AUD",
            "price": price,
            "priceValidUntil": '31-12-2030'
          }
        ],
        "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": Math.floor(Math.random() * (5 - 3 + 1)+2),
                "bestRating": 5
        },
      },
        "availability": "InStock",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": Math.floor(Math.random() * (5 - 3 + 1)+2),
          "reviewCount": Math.random().toFixed(1)*100
        }          
      }      
    }
    if(index < 200){
      itemList.push(item)
    }
  })

  if (typeof window !== `undefined`) {
    var script = document.createElement("script")
    script.type = "application/ld+json"
    itemList = JSON.stringify(itemList)
    script.text = JSON.stringify(schema)

    

    // or typeof document !== 'undefined'
    document.querySelector("head").appendChild(script)
  }
}

export default buildSchema
